<template>
  <div
    class="d-flex content-center min-vh-100 w-100 validate-page"
    :style="background ? `background-image: url('${background.url}')` : ''"
  >
    <CModal
      :title="modal.title"
      :color="modal.color"
      :show.sync="modal.show"
      :centered="true"
      class="text-left"
    >
      {{ modal.message }}
    </CModal>

    <CContainer>
      <CRow class="d-flex content-center">
        <CCol sm="12" md="12" lg="6">
          <CCardGroup>
            <CCard class="p-4" accent-color="info">
              <CCardBody>
                <CForm @submit.prevent="send">
                  <div class="d-inline-block w-100 mb-4">
                    <div
                      class="card-image d-flex justify-content-center w-100 logo-box"
                    >
                      <img
                        v-if="coloredLogo && loadedLogo"
                        :src="coloredLogo.url"
                        :alt="coloredLogo.title"
                      />
                      <img
                        v-else-if="loadedLogo"
                        src="@/assets/img/logo.svg"
                        alt="ow-logo-black"
                      />
                    </div>
                    <small class="text-muted d-inline-block w-100 mt-3">
                      Valide o seu código de acesso de cinco dígitos.
                    </small>
                  </div>
                  <CInput
                    v-model="form.token"
                    placeholder="Digite o código de cinco dígitos"
                    minlength="5"
                    maxlength="5"
                    type="number"
                    required
                    :disabled="processing"
                  ></CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <a
                        href="/login"
                        style="
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                        "
                      >
                        Voltar para o login
                      </a>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                        color="info"
                        class="px-4"
                        type="submit"
                        :disabled="processing"
                      >
                        <template v-if="processing"> Aguarde... </template>
                        <template v-else> Entrar </template>
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import AuthService from '@/services/auth.service'

export default {
  metaInfo: {
    title: 'Validar Código',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  data() {
    return {
      form: {
        token: ''
      },
      token: null,
      modal: {
        show: false,
        title: '',
        message: ''
      },
      loadedLogo: false,
      processing: false,
      logged: false
    }
  },

  computed: {
    ...mapGetters('settings', ['coloredLogo', 'background']),
    ...mapGetters('auth', ['loggedIn'])
  },

  async created() {
    this.logged = Cookies.getJSON(`${process.env.VUE_APP_COOKIES_PREFIX}user`)
    this.userHaveTwoFactorAuth = Cookies.getJSON(
      `${process.env.VUE_APP_COOKIES_PREFIX}user_have_two_factor_auth`
    )
    if (!this.userHaveTwoFactorAuth || !this.logged) {
      return this.$router.push('/login')
    }
    await this.getLogo()
  },

  methods: {
    ...mapActions('auth', ['login', 'logout']),

    async getLogo() {
      await this.$store.dispatch('settings/loadMedias')
      this.loadedLogo = true
    },

    send() {
      this.validateToken()
    },

    async validateToken() {
      this.processing = true

      const request = {
        token: this.form.token
      }

      const response = await AuthService.validateToken(request)

      this.processing = false

      if (response) {
        this.$router.push('/dashboard')
        this.login(response.token)
      } else {
        this.logout()
        this.modal = {
          show: true,
          title: 'Erro',
          message:
            'O token não é válido, por favor tente novamente mais tarde.',
          color: 'danger'
        }
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.validate-page {
  background-image: linear-gradient(
    to left bottom,
    #000000,
    #101010,
    #1b1b1b,
    #252525,
    #2f2f2f
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .card {
    border-left: none;
    border-bottom: none;
    border-right: none;

    .card-image {
      img {
        max-height: 80px;
      }
    }

    .form-group {
      .input-group {
        .input-group-text {
          background: $info;
          border-color: $info;
          color: $white;
        }
      }
    }
  }

  .modal {
    .modal-footer {
      display: none;
    }
  }
}

.logo-box {
  min-height: 40px;
}
</style>
